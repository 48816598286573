import React from 'react';
import './photos.css';
// import PhotoThump from './photoThump';
// import PhotoCard from './photoCard';
import Soulvanity2024 from '../images/soulvanity2024.JPG';
import Soulvanity2019 from '../images/soulvanity2019.jpg';
import Soulvanity2015 from '../images/soulvanity2015a.jpg';
import Soulvanity2012 from '../images/soulvanity2012a.jpg';
const photoArray = [
    {
        id:4,
        title:"Soulvanity 2024",
        img:Soulvanity2024,
        lineUp:"Petros, Sakis, Stellios, Jim, Ilias",
        photographerLink:"https://www.facebook.com/sioux.brave",
        photographerName: " Nikos Trousis"
    },
    {
        id:3,
        title:"Soulvanity 2019",
        img:Soulvanity2019,
        lineUp:"Stellios, Ilias, Stellios, Jim, Sakis",
        photographerLink:"https://www.facebook.com/jim.evgenidis",
        photographerName: " Jim Evgenidis"
    },
    {
        id:2,
        title:"Soulvanity 2015",
        img:Soulvanity2015,
        lineUp:"Sakis, Ilias, Stellios, Kostas, Jim",
        photographerLink:"https://www.facebook.com/jim.evgenidis",
        photographerName: " Jim Evgenidis"
    },
    {
        id:1,
        title:"Soulvanity 2012",
        img:Soulvanity2012,
        lineUp:"Kostas, Jim, Stellios, Sakis, Ilias",
        photographerLink:"https://www.facebook.com/dimitris.barb.7",
        photographerName: " Dimitris Barbayannis"
    },
];
// function createPhoto(photoArray) {
//     return(
      
//             <PhotoCard
//                 id={photoArray.id}
//                 key={photoArray.id}
//                 img={photoArray.img}
//                 title={photoArray.title}
//                 lineUp={photoArray.lineUp}
//                 photographerLink={photoArray.photographerLink}
//                 photographerName={photoArray.photographerName}
//                 />
//     );
// }
// function createThump(photoArray) {
//     return(
      
//             <PhotoThump
//                 id={photoArray.id}
//                 // img={}
//                 key={photoArray.id}
//                 img={photoArray.img}
//                 />
//     );
// }
function Photos() {
    function displayNone(idVar) {
        const element = document.getElementById(idVar)
        element.classList.remove('displayOn');
        element.classList.add('displayNone');
    };
    function displayOn(idVar) {
        const element = document.getElementById(idVar)
        if (element.classList.contains('displayNone')) {
            element.classList.replace('displayNone', 'displayOn');
        }
    };
    const openImage0 = () => {
        displayNone(1);
        displayNone(2);
        displayNone(3);
        displayOn(4);
    }
    const openImage1 = () => {
        displayNone(1);
        displayNone(2);
        displayNone(4);
        displayOn(3);
     }
     const openImage2 = () => {
        displayNone(1);
        displayNone(3);
        displayNone(4);
        displayOn(2);
     }
     const openImage3 = () => {
        displayNone(2);
        displayNone(3);
        displayNone(4);
        displayOn(1);
    }
    return (
        <div className='band-photo-div'>
            {/* <h1>Photos</h1> */}
            {/* {photoArray.map(createPhoto)} */}
            {/* {photoArray.map(createThump)} */}
            <br />
            <div id={photoArray[0].id} className='photo-module displayOn'>
                <img src={photoArray[0].img} className='Band-photo' alt={photoArray[0].title}/>
                <h3>{photoArray[0].title}</h3>
                <p>{photoArray[0].lineUp}</p>
                <p>Photo by 
                    <a href={photoArray[0].photographerLink}
                        rel='noreferrer'
                        target='_blank'><b>{photoArray[0].photographerName}</b>
                    </a>
                </p>
            </div>
            <div id={photoArray[1].id} className='photo-module displayNone'>
                <img src={photoArray[1].img} className='Band-photo'
                alt={photoArray[1].title}/>
                <h3>{photoArray[1].title}</h3>
                <p>{photoArray[1].lineUp}</p>
                <p>Photo by 
                    <a href={photoArray[1].photographerLink}
                        rel='noreferrer'
                        target='_blank'><b>{photoArray[1].photographerName}</b>
                    </a>
                </p>
            </div>
            <div id={photoArray[2].id} className='photo-module displayNone'>
                <img src={photoArray[2].img} className='Band-photo'
                alt={photoArray[2].title}/>
                <h3>{photoArray[2].title}</h3>
                <p>{photoArray[2].lineUp}</p>
                <p>Photo by 
                    <a href={photoArray[2].photographerLink}
                        rel='noreferrer'
                        target='_blank'><b>{photoArray[2].photographerName}</b>
                    </a>
                </p>
            </div>
            <div id={photoArray[3].id} className='photo-module displayNone'>
                <img src={photoArray[3].img} className='Band-photo'
                alt={photoArray[3].title}/>
                <h3>{photoArray[3].title}</h3>
                <p>{photoArray[3].lineUp}</p>
                <p>Photo by 
                    <a href={photoArray[3].photographerLink}
                        rel='noreferrer'
                        target='_blank'><b>{photoArray[3].photographerName}</b>
                    </a>
                </p>
            </div>
            <br />
            <div className='thump-module'>
                <a onClick={openImage0} id='photo2024'>
                    <img 
                        src={photoArray[0].img}
                        className='Band-thump' 
                    />
                </a>
            </div>
            <div className='thump-module'>
                <a onClick={openImage1} id='photo2019'>
                    <img 
                        src={photoArray[1].img}
                        className='Band-thump' 
                    />
                </a>
            </div>
            <div className='thump-module'>
                <a onClick={openImage2} id='photo2019'>
                    <img 
                        src={photoArray[2].img}
                        className='Band-thump' 
                    />
                </a>
            </div>
            <div className='thump-module'>
                <a onClick={openImage3} id='photo2019'>
                    <img 
                        src={photoArray[3].img}
                        className='Band-thump' 
                    />
                </a>
            </div>
        </div>
    );
}
export default Photos;