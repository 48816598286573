import React from 'react';
import './liveshows.css';
import Lives from './eventdata.js';
import EventCard from './event.jsx';

function createEvent(Lives) {
    return (
        <EventCard
            id={Lives.id}
            key={Lives.id}
            fbLink={Lives.fbLink}
            dateString={Lives.dateString}
            venue={Lives.venue}
            city={Lives.city}
            country={Lives.country}
            lineUp={Lives.lineUp}
            />
    );
}
function LiveShows() {
    return (
        <div className='liveShows'>
            <div className='liveshows-frame'>
                <h1>UPCOMING SHOWS</h1>
                <br />
                <ul>
                    <li className='list-item'>
                        <a 
                            className='eventLink'
                            href='https://www.facebook.com/events/1081653840134494'
                            rel='noreferrer'
                            target='_blank'
                            style={{fontWeight: 'bold'}}>
                            Sunday 24 November, Eightball Club, Thessaloniki, GR<br/>
                            MEDEN AGAN, LACHRYMOSE, SOULVANITY
                        </a>
                    </li>
                        {/* <h4>
                            Nothing scheduled.
                        </h4>
                        <h4>
                            Contact us to book a show!
                        </h4> */}
                </ul>
                <br />
                <h1>PAST EVENTS</h1>
                <br />
                <ul>
                    {Lives.map(createEvent)}
                </ul>
                <br />
            </div>
        </div>
    );
}
export default LiveShows;