import './App.css';
import Header from './components/header.jsx';
import HeroSection from './components/herosection.jsx';
import Album from './components/album.jsx';
import Footer from './components/footer.jsx';
import BandcampPlayer from './components/bandcamp.jsx';
import YouTubeVideo from './components/youtube.jsx';
import Photos from './components/photos.jsx';
import LiveShows from './components/liveshows.jsx';

function App() {
return (
        <div className='App'>
            {/* <Header /> */}
            <HeroSection />
            <Photos />
            <Album />
            <br/>
            <BandcampPlayer />
            <br/>
            <YouTubeVideo />
            <br/>
            <LiveShows />
            <Footer />
        </div>
    );
}
export default App;