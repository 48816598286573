import React from 'react';
import $ from 'jquery';
import FbLogo from '../images/facebook-icon.png';
import InstaLogo from '../images/instagram-icon.png';
import BandcampLogo from '../images/bandcamp-icon.png';
import YoutubeLogo from '../images/YouTube-icon.png';
import SpotifyLogo from '../images/Spotify-icon.png';
import './footer.css';

$(function(){
    $('[alt="Fb_logo"]').fadeIn(1000, function(){
        $('[alt="Youtube_logo"]').fadeIn(1000, function(){
            $('[alt="Insta_logo"]').fadeIn(1000, function(){
                $('[alt="Bandcamp_logo"]').fadeIn(1000, function(){
                    $('[alt="Spotify_logo"]').fadeIn(1000, function(){
                        $('[alt="AppleMusic_logo"]').fadeIn(1000);
                    });
                });
            });
        });
    });
});

function Footer() {
    return (
        <div className='footer'>
            <div className='Social-icons-div'>
                <a href='https://www.facebook.com/soulvanitytheband' className='' target='_blank' rel='noreferrer'>
                    <img src={FbLogo} className='App-social-icons' alt="Fb_logo"/>
                </a>
                <a href='https://www.youtube.com/@SoulVanityBand' className='' target='_blank' rel='noreferrer'>
                    <img src={YoutubeLogo} className='App-social-icons' alt="Youtube_logo"/>
                </a>
                <a href='https://www.instagram.com/soulvanityband/' className='' target='_blank' rel='noreferrer'>
                    <img src={InstaLogo} className='App-social-icons' alt="Insta_logo"/>
                </a>
                <a href='https://soulvanity.bandcamp.com/releases' className='' target='_blank' rel='noreferrer'>
                    <img src={BandcampLogo} className='App-social-icons' alt="Bandcamp_logo"/>
                </a>
                <a href='https://open.spotify.com/artist/2OO6A7Ga2glniC1I4qPAOK' target='_blank' rel='noreferrer'>
                    <img src={SpotifyLogo} className='App-social-icons' alt="Spotify_logo"/>
                </a>
            </div>
            <br/>

            <p className='footer-par'>
            Soulvanity  &copy; {new Date().getFullYear()}
            </p>
      </div>
    );
}
export default Footer;