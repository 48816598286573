import React from 'react';
// import $ from 'jquery';
import './herosection.css';
import DistrokidLogo from '../images/distrokid-logo-w.png'
import BandcampLogo from '../images/bandcamp-icon.png';
import YoutubeLogo from '../images/YouTube-icon.png';
import SpotifyLogo from '../images/Spotify-icon.png';
import SoulvanityLogo from '../images/soulvanity-logo-20241024.png';
function HeroSection() {
    return (
        <div className='hero-section'>
            <br />
            <img src={SoulvanityLogo} className="soulvanity-logo" alt="Soulvanity logo"/>
            <h4>Official web site</h4>
            <br /><br />
            <p className='bio'>
                <span>Soulvanity </span>is a metal band from Thessaloniki, Greece formed in 2011.
                The band members are drummer Sakis, guitarist Ilias, guitarist Petros,
                vocalist Stelios and bassist Jim.
            </p>
            <br />
            <h6>OUR MUSIC IS AVALIABLE IN ALL DIGITAL PLATFORMS THROUGH</h6>
            <br />
            <img src={DistrokidLogo} className='distro-logo' alt="Distrokid_logo"/>
            <br /><br />
            <div className='icons-container'>
                <a href='https://open.spotify.com/artist/2OO6A7Ga2glniC1I4qPAOK' className='link-icons' target='_blank' rel='noreferrer'>
                    <img src={SpotifyLogo} className='icons' alt="Spotify_logo"/>
                </a>
                <a href='https://www.youtube.com/@SoulVanityBand' className='link-icons' target='_blank' rel='noreferrer'>
                    <img src={YoutubeLogo} className='icons' alt="Youtube_logo"/>
                </a>
                <a href='https://soulvanity.bandcamp.com/releases' className='link-icons' target='_blank' rel='noreferrer'>
                    <img src={BandcampLogo} className='icons' alt="Bandcamp_logo"/>
                </a>
            {/* <img src={} className='icons' alt="_logo"/> */}
            </div>
            
        </div>
    );
}
export default HeroSection;